import axios from "./axios";

const EXAMINEDIMG_API = {
  getExaminedImage: (params) =>
    axios({
      url: `/containers/examined-images`,
      method: "GET",
      params,
    }),

  createExaminedImage: (data) =>
    axios({
      url: `/containers/examined-images`,
      method: "POST",
      data,
    }),

  deleteExamined: (id) =>
    axios({
      url: `/containers/examined-images/${id}`,
      method: "DELETE",
    }),
};

export default EXAMINEDIMG_API;
