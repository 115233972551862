import React, { useCallback, useEffect, useState } from "react";
import IGM_API from "../../apis/igmAPI";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import moment from "moment";
import PermissionsGate from "../../services/RBAC/permissionGate";

const IGMLogs = () => {
  const [igm, setIgm] = useState({});
  const [params, setParams] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [igmNumber, setIgmNumber] = useState("");
  const [igmDate, setIgmDate] = useState("");
  const [row, setRow] = useState({});
  const [submit, setSubmit] = useState(false);

  const [newIgmNumber, setNewIgmNumber] = useState("");
  const [newIgmDate, setNewIgmDate] = useState("");

  const columns = [
    {
      title: "Igm Number",
      dataIndex: "igm_number",
      key: "igm_number",
    },
    {
      title: "Igm Date",
      dataIndex: "igm_date",
      key: "igm_date",
      render: (text, row) => moment(row.igm_date).format("DD-MM-YYYY"),
    },
    // {
    //   title: "Date & Time",
    //   dataIndex: "date_time",
    //   key: "date_time",
    //   render: (text, row) => moment(row.igm_date).format("DD-MM-YYYY HH:mm:ss"),
    // },
    {
      title: "Number of Container",
      dataIndex: "container_count",
      key: "container_count",
    },
    {
      title: "Edit",
      key: "edit",
      render: (row) => (
        <PermissionsGate scopes={["edit-igm-data"]}>
          <Button
            type="primary"
            size="small"
            ghost
            onClick={() => {
              console.log("row", row);
              setRow(row);
              setNewIgmNumber(row.igm_number);
              setNewIgmDate(row.igm_date);
            }}
          >
            Edit
          </Button>
        </PermissionsGate>
      ),
    },
  ];

  const getIgmLogs = useCallback(() => {
    setLoading(true);
    IGM_API.getIgmLogs(params)
      .then((response) => {
        setIgm(response.data);
        setError(false);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  useEffect(() => {
    getIgmLogs();
  }, [getIgmLogs, params]);

  const handleUpdate = () => {
    const data = row;
    data.updated = {
      igm_number: newIgmNumber,
      igm_date: newIgmDate,
    };
    setSubmit(true);
    IGM_API.updateBulkContainers(data)
      .then((response) => {
        message.success(response.data.message);
        setRow(null);
        setIgmNumber(null);
        setNewIgmDate(null);
        getIgmLogs();
        setSubmit(false);
      })
      .catch((error) => {
        message.error("Something went wrong");
        setSubmit(false);
      });
  };

  return (
    <>
      <Card>
        <Row gutter={18}>
          <Col span={9}>
            <Input
              value={igmNumber}
              style={{ width: "100%" }}
              placeholder="Enter igm number"
              onChange={(e) => setIgmNumber(e.target.value)}
            />
          </Col>
          <Col span={9}>
            <DatePicker
              value={igmDate && moment(igmDate, "YYYY-M-D")}
              style={{ width: "100%" }}
              onChange={(e) => setIgmDate(e ? e.format("YYYY-M-D") : "")}
              placeholder="Enter igm date"
            />
          </Col>
          <Col span={6}>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setParams({
                    ...params,
                    igm_number: igmNumber,
                    igm_date: igmDate,
                  });
                }}
                loading={loading}
              >
                Search
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => {
                  setIgmNumber("");
                  setIgmDate("");
                  setParams({
                    ...params,
                    igm_number: "",
                    igm_date: "",
                  });
                }}
                loading={loading}
              >
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <Card title="IGM Logs" bodyStyle={{ padding: 0 }}>
        <Table
          columns={columns}
          loading={loading}
          dataSource={igm.results}
          pagination={{
            current: igm.page,
            pageSize: igm.limit,
            total: igm.totalResults,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: (value) => {
              console.log("value", value);
              setParams({ ...params, page: value });
            },
          }}
        />
      </Card>

      <Modal
        open={row?.igm_number ? true : false}
        footer={null}
        title="Edit Igm Number or IGM Date"
        onClose={() => {
          setRow(null);
        }}
        onCancel={() => {
          setRow(null);
        }}
        destroyOnClose={true}
      >
        <Form layout="vertical" onFinish={handleUpdate}>
          <Form.Item label="Igm Number">
            <Input
              placeholder="igm number"
              value={newIgmNumber}
              onChange={(e) => setNewIgmNumber(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Igm Date"
            help="Date format must be YYYY-M-D or YYYY-MM-DD"
            rules={[
              {
                pattern:
                  /^(202[0-9]|20[2-9][0-9])-(1[0-2]|[1-9])-(3[01]|[12][0-9]|[1-9])$/,
                message: "Invalid date format (YYYY-M-D)",
              },
            ]}
          >
            <Input
              allowClear={true}
              style={{ width: "100%" }}
              value={newIgmDate}
              onChange={(e) => setNewIgmDate(e.target.value)}
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{ marginTop: 10, marginBottom: 20 }}
            loading={submit}
          >
            Confirm and Update
          </Button>
        </Form>

        <Alert
          type="warning"
          description={`Changing this data will be reflect on ${row?.container_count} containers `}
        />
      </Modal>
    </>
  );
};

export default IGMLogs;
