import React, { useCallback, useEffect, useState } from "react";
import CONTAINER_API from "../../apis/containerAPI";
import {
  Button,
  Card,
  Table,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Space,
  Drawer,
  message,
  Typography,
  Tag,
} from "antd";
import dayjs from "dayjs";
import { CORE_API } from "../../apis/api";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import PermissionsGate from "../../services/RBAC/permissionGate";

const Containers = () => {
  const [filterForm] = Form.useForm();
  const [addContainerForm] = Form.useForm();
  const [containers, setContainers] = useState({});
  const [params, setParams] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cfs, setCfs] = useState([]);
  const [chapterHeadings, setChapterHeadings] = useState([]);
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const columns = [
    {
      title: "Container number",
      dataIndex: "container_number",
      key: "container_number",
      render: (text, row) => (
        <Link to={`/container-management/containers/${row._id}`}>
          <Typography.Link copyable>{text}</Typography.Link>
        </Link>
      ),
      width: 200,
    },
    {
      title: "IGM number",
      dataIndex: "igm_number",
      key: "igm_number",
      render: (text) => <Typography.Text copyable>{text}</Typography.Text>,
      width: 150,
    },
    {
      title: "IGM date",
      dataIndex: "igm_date",
      key: "igm_date",
      render: (text, row) => moment(row.igm_date).format("DD-MM-YYYY"),
      width: 150,
    },
    {
      title: "CFS name",
      dataIndex: "cfs_name",
      key: "cfs_name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <Typography.Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
          {text}
        </Typography.Text>
      ),
    },
    {
      title: "Scanned Images",
      dataIndex: "scanned_images",
      key: "scanned_images",
      render: (text, row) =>
        row.scanned_images.length > 0 ? (
          <Tag color="green">Yes</Tag>
        ) : (
          <Tag color="orange">No</Tag>
        ),
    },
    {
      title: "Examined Images",
      dataIndex: "examined_images",
      key: "examined_images",
      render: (text, row) =>
        row.examined_images.length > 0 ? (
          <Tag color="green">Yes</Tag>
        ) : (
          <Tag color="orange">No</Tag>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, row) => (
        <PermissionsGate scopes={["delete-container"]}>
          <Button
            size="small"
            type="default"
            danger
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to delete this container?"
                )
              ) {
                deleteContainer(row._id);
              }
            }}
          >
            Delete
          </Button>
        </PermissionsGate>
      ),
    },
  ];

  const deleteContainer = (id) => {
    CONTAINER_API.deleteContainer(id).then(() => {
      message.success("Container deleted successfully");
      getContainers();
    });
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onFilter = () => {
    const values = filterForm.getFieldsValue();
    values.igm_date = values.igm_date
      ? `${dayjs(values.igm_date[0]).format("YYYY-M-D")}~${dayjs(
          values.igm_date[1]
        ).format("YYYY-M-D")}`
      : undefined;
    const updatedParams = { ...params, ...values };
    const filteredValues = Object.keys(updatedParams).reduce((acc, key) => {
      const value = values[key];
      if (value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});
    console.log("filteredValues", filteredValues);
    setParams(filteredValues);
  };
  const getCFS = () => {
    CORE_API.getCoreData("cfs").then((res) => {
      setCfs(res.data);
    });
  };
  const getChapterHeadings = () => {
    CORE_API.getCoreData("chapter-headings").then((res) => {
      setChapterHeadings(res.data);
    });
  };

  const getContainers = useCallback(() => {
    setLoading(true);
    CONTAINER_API.getContainers(params)
      .then((response) => {
        setContainers(response.data);
        setError(false);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  const add = () => {
    setSubmitting(true);
    const data = addContainerForm.getFieldsValue();
    data.igm_date = dayjs(data.igm_date).format("YYYY-MM-DD");
    CONTAINER_API.createContainers(data).then((res) => {
      getContainers();
      addContainerForm.resetFields();
      setSubmitting(false);
      onClose();
      message.success(
        `Container for ${res.data.container_number} created successfully`
      );
    });
  };

  useEffect(() => {
    getContainers();
    getCFS();
    getChapterHeadings();
  }, [getContainers, params]);

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <Card size="small">
          <Form layout="vertical" form={filterForm}>
            <Row gutter={[8]}>
              <Col sm={8}>
                <Form.Item label="Container Number" name="container_number">
                  <Input placeholder="Container Number" />
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item label="IGM Number" name="igm_number">
                  <Input placeholder="IGM Number" />
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item label="IGM Date" name="igm_date">
                  <DatePicker.RangePicker
                    style={{ width: "100%" }}
                    format="YYYY/MM/DD"
                  />
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item label="CFS Name" name="cfs_name">
                  <Select
                    allowClear
                    placeholder="Search by CFS name"
                    optionFilterProp="children"
                    showSearch
                    filterSort={(optionA, optionB) => {
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase());
                    }}
                  >
                    {cfs.map((item) => (
                      <Select.Option value={item.cfs_id} key={item.id}>
                        {item.cfs_name}- {item.cfs_id}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item label="Chapter heading" name="chapter_headings">
                  <Select
                    allowClear
                    placeholder="Search by chapter heading"
                    showSearch
                    optionFilterProp="children"
                    filterSort={(optionA, optionB) => {
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase());
                    }}
                  >
                    {chapterHeadings.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={4}>
                <Form.Item label=" ">
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={onFilter}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
              <Col sm={4}>
                <Form.Item label=" ">
                  <Button
                    block
                    danger
                    onClick={() => {
                      setParams({});
                      filterForm.resetFields();
                    }}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card
          title={`Containers (${containers.totalResults || 0})`}
          bodyStyle={{ padding: 0 }}
          extra={
            <Space size="middle">
              <PermissionsGate scopes={["create-containers"]}>
                <Button onClick={() => setOpen(true)}>Create Container</Button>
              </PermissionsGate>
              <PermissionsGate scopes={["upload-containers"]}>
                <Button
                  onClick={() =>
                    navigate("/container-management/upload-container-excel")
                  }
                >
                  Upload IGM List
                </Button>
              </PermissionsGate>
            </Space>
          }
        >
          <Table
            dataSource={containers.results}
            columns={columns}
            loading={loading}
            rowKey="id"
            pagination={{
              current: containers.page,
              pageSize: containers.limit,
              total: containers.totalResults,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              onChange: (value) => {
                console.log("value", value);
                setParams({ ...params, page: value });
              },
            }}
          />
        </Card>
      </Space>
      <Drawer
        title="Create Container"
        width={500}
        onClose={onClose}
        open={open}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form form={addContainerForm} layout="vertical">
          <Form.Item
            label="IGM Number"
            rules={[
              { required: true },
              { max: 8, message: "Maximum 8 charecters allowed" },
            ]}
            name="igm_number"
          >
            <Input placeholder="igm number" />
          </Form.Item>
          <Form.Item
            label="IGM Date"
            name="igm_date"
            rules={[{ required: true }]}
          >
            <DatePicker
              allowClear={true}
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                console.log(date, dateString);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Container Number"
            rules={[{ required: true }]}
            name="container_number"
          >
            <Input placeholder="container number" />
          </Form.Item>
          <Form.Item
            label="CFS Name"
            name="cfs_name"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              placeholder="Search by CFS name"
              optionFilterProp="children"
              showSearch
              filterSort={(optionA, optionB) => {
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase());
              }}
            >
              {cfs.map((item) => (
                <Select.Option value={item.cfs_id} key={item.id}>
                  {item.cfs_name}- {item.cfs_id}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Container Description"
            rules={[{ required: true }]}
            name="description"
          >
            <Input.TextArea placeholder="container description" />
          </Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            onClick={add}
            loading={submitting}
          >
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export const ChapeterHeadingList = (props) => {
  const [chapterHeadings, setChapterHeadings] = useState([]);
  const getChapterHeadings = () => {
    CORE_API.getCoreData("chapter-headings").then((res) => {
      setChapterHeadings(res.data);
    });
  };
  useEffect(() => {
    getChapterHeadings();
  }, []);
  return (
    <Select
      allowClear
      placeholder="Search by chapter heading"
      showSearch
      optionFilterProp="children"
      filterSort={(optionA, optionB) => {
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase());
      }}
      onChange={(value) => {
        props.setData && props.setData(value);
      }}
      style={{ width: "100%" }}
    >
      {chapterHeadings.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default Containers;
