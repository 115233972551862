import axios from "./axios";

const IGM_API = {
  getIgmLogs: (params) =>
    axios({
      url: `/containers/igm-logs`,
      method: "GET",
      params,
    }),

  updateBulkContainers: (data) =>
    axios({
      url: `/containers/bulk-container-update`,
      method: "POST",
      data,
    }),
};

export default IGM_API;
